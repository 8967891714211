.home {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 0%;
  left: 15px;
  
  

}
.header{
  position:sticky;
  padding: 2px;
  align-items: centery;
  box-shadow: 5px 10px 18px #888888;
  z-index: 100%;
}

.header_logo {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  min-width: 80px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 5px;
 
}
.header_logo_icon{
  
width: 54px;
height: 35px;
left: 0px;
top: 31px;
display: inline-block;
height: 35px;
line-height: 50px;
margin-right: 0px;
min-width: 40px;
margin-top: 10px;
margin-left: 3px;
margin-bottom: 10px;
border-radius: 5px;
 
}

.header>h2{
    position: absolute;
    width: 204px;
    height: 30px;
    top: 10px;
    left: 82px;
    font-size: 20px;
    font-style: bold;
}
.header_shop{
position: absolute;
display: inline-block;
width: 180px;
height: 30px;
top: 8px;
margin-top: 10px;
font-size: 24px;
color: #4F4F4F;
font-family: 'M PLUS 1p', sans-serif;
}
.header_bag{
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 30px;
  top: 8px;
  margin-top: 10px;
  font-size: 24px;
  color: #4F4F4F;
  }
  .home_slide{
    height: 95vh;
  }

  